import React from "react"
import PageNotFound from "../components/PageNotFound"
import Layout from "../components/Layout"

export default function PageDoesntExist() {
  return (
    <Layout>
      <header>
        <title>
          Blackfort - Page not found
        </title>
      </header>
      <PageNotFound />
    </Layout>
  )
}
