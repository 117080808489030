import React from 'react'
import styled from 'styled-components'

const PageNotFound = () => {
    return (
        <NotFound>
            <h1>HTTP 404 - Page Not Found</h1>
        </NotFound>
    )
}

export default PageNotFound

const NotFound = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100%;
    background: #FFB700;
    position: absolute;
`
